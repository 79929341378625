<template>
  <div class="monitor-device-page x-background-color x-box-shadow">
    <x-table new-style :columns="table.columns" :data="table.data" :loading="table.loading">
      <template #header>
        <div class="table-header">
          <div class="search-pane">
            <p>
              <span>基地/场景：</span>
              <Cascader :data="[]" style="width: 200px"></Cascader>
            </p>
            <p>
              <span>监测类别：</span>
              <Cascader :data="[]" style="width: 200px"></Cascader>
            </p>
            <p>
              <span style="width: 120px">监测点名称：</span>
              <Input placeholder="输入监测点名称查询"></Input>
            </p>
            <p>
              <span style="width: 100px">监测名称：</span>
              <Input placeholder="输入监测名称查询"></Input>
            </p>
            <p>
              <Button type="primary" icon="md-search" @click="search">查询</Button>
            </p>
          </div>
          <div>
            <Button type="primary" icon="ios-add" @click="add">新增监测</Button>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="footer">
          <Page
            :current="page.pageNo"
            :page-size="page.pageSize"
            :total="page.total"
            :show-elevator="true"
            :show-total="true"
            @on-change="pageChange"
          ></Page>
        </div>
      </template>
    </x-table>
  </div>
</template>

<script>
export default {
  name: "",
  components: {
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: '监测点名称'
          },
          {
            title: '监测名称'
          },
          {
            title: '监测类别'
          },
          {
            title: '罗拉设备'
          },
          {
            title: '通道类型/名称'
          },
          {
            title: '监测值范围'
          },
          {
            title: '量程范围'
          },
          {
            title: '微安值范围'
          },
          {
            title: '百分比(%)'
          },
          {
            title: '读取位数'
          },
          {
            title: '备注'
          },
          {
            title: '状态'
          },
          {
            title: '操作'
          }
        ],
        data: [],
        loading: false
      },
      page: {
        pageNo: 1,
        pageSize: 13,
        total: 0
      },
      sceneType: []
    };
  },
  methods: {
    search() {},

    add() {},

    pageChange(pageNo) {},

    getSceneType() {
      this.$post(this.$api.COMMON.DICT, {
        name: '场景类型'
      }).then(res => {
        this.sceneType = res
      })
    }
  },
  mounted() {
    this.getSceneType()
  }
};
</script>

<style lang="less" scoped>
.monitor-device-page {
  width: 100%;
  height: 100%;
  .table-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 16px;
    .search-pane {
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 1;
      flex-grow: 1;
      p {
        display: flex;
        align-items: center;
        padding-right: 16px;
      }
    }
  }
  .footer {
    padding: 16px;
    text-align: right;
  }
}
</style>